.App {
}

.App-header {
  background-color: #222;
  height: 80px;
  padding: 10px;
  color: white;
  display: grid;
  grid-template-columns: 90px auto 12em;
}

.InchToMM {
  margin-right: 0;
  margin-left: auto;
}

.InchToMM label {
  text-align: right;
  font-size: smaller;
  vertical-align: text-bottom;
  display:inline-block;
  width: 6em;
  margin-right: 5px;
}

.InchToMM div {
  padding: 5px;
}

.InchToMM input {
  text-align: right;
  font-size: smaller;
  width: 5em;
  vertical-align: top;
}

.App-data {
  display: grid;
  grid-template-columns: 320px auto;
  margin: 10px;
}

.App-values {
  margin: 0px 10px 0 10px;
}

.App-values h4 {
  margin: 15px 0 10px 0;
}

.Specification {
	display: grid;
	grid-template-columns: 120px auto;
	grid-column-gap: 8px;
	grid-row-gap: 3px;
  justify-items: left;  
  align-items: center;
  width: 100%;
  font-size: smaller;
  margin-bottom: 3px;
}

.Specification select {
  width: 100%;
  font-size: small;
}

.App-drawing {
  border: solid black;
  border-width: 0px 0px 0px 1px;
  align-self: top;
  width: 100%;
  height: 100%;
  padding: 0 0 0 10px;
  justify-content: center;  
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-tips {
  font-size: smaller;
  text-align: left;
}

.Download
{
  display: flex;
  width: 100%;
  margin: auto;
}

.Download a
{
  margin-right: 10px;
}

.Download button
{
  background-color: white; 
  border: none;
  color: black; 
  text-align: center;
  font-size: 12px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  border: 2px solid DodgerBlue;  
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  height: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 0px;
  padding: .2em 1em 0em 1em;
}

.Download button:hover {
  background-color: DodgerBlue;
  color: white;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
