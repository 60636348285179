body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.overridden {
    background-color: LemonChiffon;
}

[disabled]
{
  pointer-events: none;
  border: none;
}

.input-values {
	display: grid;
	grid-area: input-area;
	grid-template-columns: 120px auto;
	grid-column-gap: 8px;
	grid-row-gap: 3px;
  justify-items: left;  
  align-items: center;
}

.input-values label {
  text-align: right;
  font-size: smaller;
  vertical-align: middle;
}

.input-values input {
  text-align: right;
  font-size: small;
  width: 5em;
  vertical-align: top;
}

.input-values select {
  width: 100%;
  font-size: small;
}

.calculate-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}



.svg-container
{
    margin: 30px;
}

.svg-inner
{
    display: block;
    margin: auto; /*center the whole thing*/
    overflow: hidden;  
}

.App-drawing img {
    width: 30px;
}


.App {
}

.App-header {
  background-color: #222;
  height: 80px;
  padding: 10px;
  color: white;
  display: grid;
  grid-template-columns: 90px auto 12em;
}

.InchToMM {
  margin-right: 0;
  margin-left: auto;
}

.InchToMM label {
  text-align: right;
  font-size: smaller;
  vertical-align: text-bottom;
  display:inline-block;
  width: 6em;
  margin-right: 5px;
}

.InchToMM div {
  padding: 5px;
}

.InchToMM input {
  text-align: right;
  font-size: smaller;
  width: 5em;
  vertical-align: top;
}

.App-data {
  display: grid;
  grid-template-columns: 320px auto;
  margin: 10px;
}

.App-values {
  margin: 0px 10px 0 10px;
}

.App-values h4 {
  margin: 15px 0 10px 0;
}

.Specification {
	display: grid;
	grid-template-columns: 120px auto;
	grid-column-gap: 8px;
	grid-row-gap: 3px;
  justify-items: left;  
  align-items: center;
  width: 100%;
  font-size: smaller;
  margin-bottom: 3px;
}

.Specification select {
  width: 100%;
  font-size: small;
}

.App-drawing {
  border: solid black;
  border-width: 0px 0px 0px 1px;
  align-self: top;
  width: 100%;
  height: 100%;
  padding: 0 0 0 10px;
  justify-content: center;  
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-tips {
  font-size: smaller;
  text-align: left;
}

.Download
{
  display: flex;
  width: 100%;
  margin: auto;
}

.Download a
{
  margin-right: 10px;
}

.Download button
{
  background-color: white; 
  border: none;
  color: black; 
  text-align: center;
  font-size: 12px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  border: 2px solid DodgerBlue;  
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  height: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 0px;
  padding: .2em 1em 0em 1em;
}

.Download button:hover {
  background-color: DodgerBlue;
  color: white;
}


@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 16px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: DodgerBlue;
  -webkit-transition: .2s;
  transition: .2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}

input:checked + .slider {
  background-color: Coral;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-label-left {
  font-size: 1em;  
  margin: 0px 5px;
}

.slider-label-right {
  font-size: 1em;  
  margin: 5px 5px;
}
