
.svg-container
{
    margin: 30px;
}

.svg-inner
{
    display: block;
    margin: auto; /*center the whole thing*/
    overflow: hidden;  
}

.App-drawing img {
    width: 30px;
}

