
.input-values {
	display: grid;
	grid-area: input-area;
	grid-template-columns: 120px auto;
	grid-column-gap: 8px;
	grid-row-gap: 3px;
  justify-items: left;  
  align-items: center;
}

.input-values label {
  text-align: right;
  font-size: smaller;
  vertical-align: middle;
}

.input-values input {
  text-align: right;
  font-size: small;
  width: 5em;
  vertical-align: top;
}

.input-values select {
  width: 100%;
  font-size: small;
}

.calculate-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
}

.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

